<template>
  <div class="flex justify-between h-16">
    <div class="flex">
      <div class="flex-shrink-0 flex items-center">
        <h1 class="text-lg font-bold">Lickety Script</h1>
      </div>

      <div class="flex-shrink-0 flex items-center px-3">v{{version}}</div>

      <div class="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
        <a class="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900" href="https://licketyscript.app">Home</a>
        <a class="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900" href="https://ui.licketyscript.app">Create Script</a>
        <a class="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900" href="https://licketyscript.app/#howto">How To</a>
        <a class="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900" href="https://licketyscript.app/#about">About</a>
      </div>

      <div class="flex-shrink-0 flex items-center ml-20">
      </div>
    </div>

    <div class="-mr-2 flex items-center sm:hidden">
      <div class="-mr-2 flex md:hidden">
        <MobileNav/>
      </div>
    </div>
  </div>
</template>

<script>
import MobileNav from "@/components/MobileNav";
import axios from "axios";
export default {
  name: "NavBar",
  components: {
    MobileNav
  },
  data() {
    return {
      version: '',
    }
  },
  created () {
    this.getStatus()
  },
  methods: {
    getStatus: function () {
      axios({
        url: 'https://api.licketyscript.app/status',
        method: 'GET'
      }).then(result => {
        this.version = result.data.version
      }, error => {
        console.error(error)
      })
    }
  }
}
</script>

<style scoped>
.router-link-exact-active {
border-bottom-width: 2px;
--tw-border-opacity: 1;
border-color: rgba(99, 102, 241, var(--tw-border-opacity));
}

</style>
